<template>
  <div>
    <v-list-item-content style="align-self: center" class="py-0">
      <v-list-item-title
        class="text-capitalize d-flex align-center"
        style="font-size: 14px"
      >
        <hb-icon
          class="pr-1"
          style="position: relative; top: -1px"
          color="#101318"
          small
          >mdi-account</hb-icon
        >
        <span class="hb-font-body-medium hb-text-night mx-1"
          >{{ contact.first }} {{ contact.last }}</span
        >&nbsp;
        <hb-status v-if="contact.status">{{
          contact.status
        }}</hb-status
        >&nbsp;
        <span style="color: #637381" v-if="this.displayContactBalance">
          (Balance
          {{ balanceDue | formatMoney }})</span
        >
      </v-list-item-title>
      <v-list-item-subtitle>
        <span
          v-if="
            contact.Phones && contact.Phones.length > 0
          "
          >{{ contact.Phones[0].phone | formatPhone }}</span
        >
        <span
          v-if="
            contact.Phones &&
            contact.Phones.length > 0 &&
            contact.email
          "
        >
          |
        </span>
        <span> {{ contact.email }}</span>
      </v-list-item-subtitle>
      <span
        v-for="(lease, index) in leases"
        :key="'lease' + index"
      >
        <div v-if="lease && lease.id">
          <v-list-item-subtitle
            class="contact-space-pointer d-flex align-center"
            @click="viewLease(r)"
          >
            <HbUnitIcon
              :unit="lease.Unit"
              :applyStroke="false"
              small
            />
            <span class="pl-1">
              {{ lease.Unit.number }} | <span v-if="lease.Unit.Property.number">{{lease.Unit.Property.number}} - </span>{{ lease.Unit.Property.Address | formatAddress('-', true) }}
            </span>
          </v-list-item-subtitle>
        </div>
      </span>
    </v-list-item-content>
  </div>        
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import methods from "../../../assets/api/methods";

export default {
  name: "LinkContact",
  props: ["contact", "leases"],
  computed: {
    ...mapGetters({
      currentProperty: "contactStore/getCurrentProperty",
    }),
    balanceDue() {
      if (this.contact.Leases) {
        let currentProperty = this.currentProperty;
        return (
          Math.round(
            this.contact.Leases.reduce(function (a, b) {
              return b.Unit.property_id === currentProperty
                ? a + b.open_balance
                : a + 0;
            }, 0) * 1e2
          ) / 1e2
        );
      }
    },
    displayContactBalance() {
      return this.balanceDue > 0;
    },
  },
  methods: {
    unitInfo(lease) {
      return (lease?.Unit?.number ? lease.Unit.number + ' | ' : '') + (lease?.Unit?.Address?.city ? lease.Unit.Address.city : '') + (lease?.Unit?.Address?.state ? ' - ' + lease.Unit.Address.state : '') + (lease?.Unit?.Address?.country ? ' - ' + lease.Unit.Address.country : '') + (lease?.Unit?.Address?.address ? ' - ' + lease.Unit.Address.address : '') + (lease?.Unit?.Address?.address2 ? ' - ' + lease.Unit.Address.address2 : '');
    }
  }
}

</script>
